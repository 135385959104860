import { MyGameList } from '../pages/Minigame/List';

function AdminMinigameMyRoute() {
  return <MyGameList />;
}

export const Component = AdminMinigameMyRoute;

export function clientLoader() {
  return null;
}
